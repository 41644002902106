import React from 'react'
import { Link } from 'react-router-dom';
import LinkedInBug from '../images/linkedin-bug.svg';
import './NavBar.scss';

function NavBar() {
    return (
        <div className="NavBar">
            <div className="NavBar-logo-container">
                <Link to="/" className="NavBar-nav-item-link">JD</Link>
            </div>
            <nav className="NavBar-nav">
                <ul className="NavBar-nav-list">
                    <li className="NavBar-nav-item">
                        <Link to="blog" className="NavBar-nav-item-link">Blog</Link>
                    </li>
                    <li className="NavBar-nav-item">
                        <a href="https://www.linkedin.com/in/jeremydejno/" className="NavBar-nav-item-link" target='blank'>
                            <img className="NavBar-nav-item-logo" src={LinkedInBug} alt="Jeremy Dejno's LinkedIn Profile"></img>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default NavBar;