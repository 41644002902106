import React from "react";
import { Link } from "react-router-dom";
import JourneyItem from "../components/home/JourneyItem";
import EpicLogo from "../images/epic.png";
import LinkedInLogo from "../images/linkedin.png";
import EmberLogo from "../images/ember-logo.svg";
import JavaLogo from "../images/java-logo.png";
import NarbonneVolleyLogo from "../images/narbonne-volley-logo.png";
import UCIrvineLogo from "../images/uc-irvine-logo.png";
import "./Home.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.headerTriangleRef = React.createRef();
  }

  componentDidMount() {
    const onJourneyItemEnter = (entries, observer) => {
      const hiddenClassName = "Home-journey-item-hidden";

      entries.forEach(({ target, isIntersecting }) => {
        if (target.classList.contains(hiddenClassName) && isIntersecting) {
          target.classList.remove(hiddenClassName);
        } else {
          target.classList.add(hiddenClassName);
        }
      });
    };

    this.observer = new IntersectionObserver(onJourneyItemEnter, {
      threshold: 1,
      rootMargin: "20px 1000px",
    });

    document
      .querySelectorAll(".Home-journey-item")
      .forEach((el) => this.observer.observe(el));

    window.requestAnimationFrame(() =>
      this.headerTriangleRef.current.classList.add(
        "Home-header-triangle-1-skew"
      )
    );
  }

  render() {
    return (
      <div className='Home'>
        <header className='Home-header'>
          <div
            className='Home-header-triangle-1'
            ref={this.headerTriangleRef}
          ></div>
          <h1 className='Home-header-title'>Jeremy Dejno</h1>
          <h3>full-stack developer. tech lead. <span role='img' aria-label='coffee'>☕</span> lover.</h3>
        </header>
        <div className='Home-body'>
          <section className='Home-section'>
            <h2 className='Home-section-title'>A little about me</h2>
            <div className='Home-section-body'>
              Hi there{" "}
              <span role='img' aria-label='hello'>
                👋
              </span>
              , thanks for stopping by!
              <br />
              <br />
              My name is Jeremy. I'm a full-stack developer that got my start in
              software in 2016 at{" "}
              <img
                className='Home-inline-img'
                src={EpicLogo}
                alt='Epic Systems Logo'
              ></img>
              .
              <br />
              <br />
              Since then, my journey has taken me to{" "}
              <img
                className='Home-inline-img'
                src={LinkedInLogo}
                alt='LinkedIn Logo'
              ></img>{" "}
              where I've spent my time building LinkedIn Learning.
              <br />
              <br />
            </div>
            <footer className='Home-section-footer'>
              If you'd like to learn more about me, check out my{" "}
              <Link to='blog'>writing</Link>
            </footer>
          </section>
          <section
            id='home-journey-section'
            className='Home-section Home-journey'
          >
            <h2 className='Home-section-title'>Career Journey</h2>
            <hr className='Home-journey-divider'></hr>
            <div className='Home-section-body'>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='Present'
                title='Software Engineering Manager'
                logo={LinkedInLogo}
                logoAlt='LinkedIn Logo'
              >
                Engineering lead building LinkedIn Learning
              </JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2022.06'
                title='Staff Software Engineer'
                logo={LinkedInLogo}
                logoAlt='LinkedIn Logo'
              >
                Building LinkedIn Learning with{" "}
                <img
                  className='Home-inline-img'
                  src={EmberLogo}
                  alt='Ember.js Logo'
                ></img>{" "}
                and{" "}
                <img
                  className='Home-inline-img'
                  src={JavaLogo}
                  alt='Java Logo'
                ></img>{" "}
              </JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2020.04'
                title='Senior Software Engineer'
                logo={LinkedInLogo}
                logoAlt='LinkedIn Logo'
              >
                Building LinkedIn Learning with{" "}
                <img
                  className='Home-inline-img'
                  src={EmberLogo}
                  alt='Ember.js Logo'
                ></img>{" "}
                and{" "}
                <img
                  className='Home-inline-img'
                  src={JavaLogo}
                  alt='Java Logo'
                ></img>{" "}
              </JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2018.11'
                title='Frontend Software Engineer'
                logo={LinkedInLogo}
                logoAlt='LinkedIn Logo'
              ></JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2017.02'
                title='QA Team Lead'
                logo={EpicLogo}
                logoAlt='Epic Logo'
              ></JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2016.01'
                title='Quality Assurance'
                logo={EpicLogo}
                logoAlt='Epic Logo'
              ></JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2014.08'
                title='Profession Volleyball Player'
                logo={NarbonneVolleyLogo}
                logoAlt='Narbonne Volley Logo'
              ></JourneyItem>
              <JourneyItem
                styleName='Home-journey-item Home-journey-item-hidden'
                date='2010.07'
                title='Student Athlete'
                logo={UCIrvineLogo}
                logoAlt='Epic Logo'
              ></JourneyItem>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Home;
