import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

export default function Article() {
  let params = useParams();

  let [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(`/articles/${params.articleId}.md`)
      .then((res) => res.text())
      .then((md) => setContent({ md }));
  }, [params]);

  return (
    <article
      style={{
        maxWidth: "700px",
        margin: "auto",
        textAlign: "left",
        padding: "0 24px",
      }}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        children={content.md}
      />
    </article>
  );
}
