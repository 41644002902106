import React from "react";
import { Link } from "react-router-dom";
import "./Blog.scss";

const articles = [
  {
    title: "Growth & Craft as an Engineer",
    date: "2022.08.28",
    slug: "growth-and-craft",
  },
];

const createArticleItem = ({ title, date, slug }) => (
  <li>
    <Link to={`/articles/${slug}`} className='Blog-article-link'>
      <i>{title}</i> <span className='Blog-article-link-date'>{date}</span>
    </Link>
  </li>
);

export default function Blog() {
  return (
    <article>
      <header>
        <h1>Blog</h1>
      </header>
      <ul className='Blog-articles-list'>{articles.map(createArticleItem)}</ul>
    </article>
  );
}
