import { Outlet } from "react-router-dom";
import React from 'react';
import NavBar from './common/NavBar';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <main className="App-main">
        <Outlet />
      </main>
      <footer className="App-footer">
        <div className="App-footer-container">
          <a className="link" href="mailto:jeremy.dejno@gmail.com">jeremy.dejno@gmail.com</a>
        </div>
      </footer>
    </div>
  );
}

export default App;
