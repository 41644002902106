import React from "react";
import "./JourneyItem.scss";

function JourneyItem(props) {
  return (
    <div className={`JourneyItem-item ${props.styleName}`}>
      <h3 className="JourneyItem-item-date">{props.date}</h3>
      <span className="JourneyItem-item-separator">&middot;</span>
      <div className="JourneyItem-item-logo">
        <img
          className="Home-inline-img"
          src={props.logo}
          alt={props.logoAlt}
        ></img>{" "}
      </div>
      <span className="JourneyItem-item-separator">&middot;</span>
      <div className="JourneyItem-item-container">
        <h4 className="JourneyItem-item-title">{props.title}</h4>
        <div className="JourneyItem-item-details">{props.children}</div>
      </div>
    </div>
  );
}

export default JourneyItem;
